import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { 
  Flex,
  useToast,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay, 
  Button,
  Badge,
  Text,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { API_BASE_URL } from "constants/apiConstants";
import axios from 'axios';
import { Link } from "react-router-dom";
import { MdDelete,MdEdit,MdBlock,MdDownload } from "react-icons/md";
import { ASSETS_URL } from "constants/apiConstants";
import { FilterMatchMode } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';

export default function ReorderDemo() {
  const [properties,setProperties] = useState([]);
    const toast = useToast()
    const [confirmBoxMessage, setConfirmBoxMessage] = useState();
    const [confirmBoxTitle, setConfirmBoxTitle] = useState();
    const [isOpen, setIsOpen] = React.useState();
    const [propertyId, setPropertyId] = useState();
    const [eventType, setEventType] = useState();
    const cancelRef = React.useRef();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [isTrue, setIsTrue] = useState(true);

    const fetchProperties = () => {
      const payload = {
        'rowCount' : rowCount
      };
      axios.post(API_BASE_URL+'get/property/list',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setProperties(response.data.data.propertyList);
        setRowCount(response.data.data.rowCount);
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const columns = [
        { field: 'id', header: 'Actions' },
        { field: 'status', header: 'Status' },
        { field: 'property_id', header: 'Id' },
        { field: 'cover_photo', header: 'Image' },
        { field: 'title', header: 'Title' },
        { field: 'listing_type', header: 'Type' },
        { field: 'location_title', header: 'Location' },
        { field: 'brochure', header: 'Brochure' },
        { field: 'floor_plan', header: 'Floor plan' },
        { field: 'user.name', header: 'Agent' },
        { field: 'developer.name', header: 'Developer' },
        { field: 'square_feet', header: 'Square feet' },
        { field: 'square_meter', header: 'Square meter' },
        { field: 'amount', header: 'Amount' },
        { field: 'currency', header: 'Currency' },
        { field: 'date', header: 'Created At' },
    ];

    const handleRowOrderChange = async (params) => {
      const payload ={
        'dropIndex' : params.dropIndex,
        'value' : params.value
      }

      axios.post(API_BASE_URL+'update/property/row-priority',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setProperties(params.value)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    const onClose = () => setIsOpen(false)

    const openHidePropertyConfirmBox = (id,type) => {
      setConfirmBoxTitle("Hide Property");
      setConfirmBoxMessage("are you sure want to hide this property?");
      setIsOpen(true);
      setPropertyId(id);
      setEventType(type);
    }
  
    const openDeletePropertyConfirmBox = (id,type) => {
      setConfirmBoxTitle("Delete Property");
      setConfirmBoxMessage("Are you sure you want your delete this listing? This listing will be deleted permanently. This action can’t be undone.");
      setIsOpen(true);
      setPropertyId(id);
      setEventType(type);
    }

    const actionBodyTemplate = (rowData) => {
      return (
        <Flex cursor='pointer' mx="auto">
          <Link to={`/admin/properties/edit-property/${rowData.id}`}>
            <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
          </Link>

          <Link onClick={e => openHidePropertyConfirmBox(rowData.id,1)}>
            <Icon color='secondaryGray.500' as={MdBlock} w='20px' h='20px'/>
          </Link>

          <Link onClick={e => openDeletePropertyConfirmBox(rowData.id,2)}>
            <Icon color='secondaryGray.500' as={MdDelete} w='20px' h='20px' />
          </Link>
        </Flex>
      );
    };

    const statusBodyTemplate = (rowData) => {
      if(rowData.status === 1){
        var statusValue = <Badge colorScheme='green'>Active</Badge>;
      }else if(rowData.status === 2){
        var statusValue = <Badge colorScheme='red'>In Active</Badge>;
      }
      return (
        <Flex align='center'>
          <Text
            color={textColor}
            fontSize='md'
            fontWeight='500'>
              {statusValue}
          </Text>
        </Flex>
      );
    };

    const imageBodyTemplate = (rowData) => {
      return (
        <Flex align='center'>
          <Avatar
            src={ASSETS_URL+"properties/media/small/"+rowData.cover_photo} 
            h='60px'
            w='60px'
            me='10px'
          />
        </Flex>
      );
    };

    const brochureBodyTemplate = (rowData) => {
      return (
        <Flex align='center'>
          <Text
            color={textColor}
            fontSize='md'
            >
              <a href = {ASSETS_URL+'properties/brochure/'+rowData.brochure} rel='noreferrer' target = "_blank"><Icon as={MdDownload} color="inherit" /></a>
          </Text>
        </Flex>
      );
    };

    const floorPlanBodyTemplate = (rowData) => {
      return (
        <Flex align='center'>
          <Text
            color={textColor}
            fontSize='md'
            >
              <a href = {ASSETS_URL+'properties/floorplan/'+rowData.floor_plan} rel='noreferrer' target = "_blank"><Icon as={MdDownload} color="inherit" /></a>
          </Text>
        </Flex>
      );
    };

    const eventHandle = () => {
      setIsOpen(false);
      const payload = {
        'id':propertyId
      };
  
      if(eventType === 1){
        axios.post(API_BASE_URL+'hide/property',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          window.location.reload();
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }else if(eventType === 2){
        axios.post(API_BASE_URL+'delete/property',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          window.location.reload();
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
  
    const renderHeader = () => {
      return (
          <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
              <div className='d-flex'>
                <div className='search-box dev-search-box'>
                  <IconField iconPosition="left">
                      <InputIcon className="pi pi-search" />
                      <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                  </IconField>
                </div>
              </div>
          </div>
      );
    };

    const scrollEvent = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && isTrue) { 
        const payload = {
          'rowCount' : rowCount
        };
        axios.post(API_BASE_URL+'get/property/list',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then((response) => {
          if(response.data.data.propertyList.length > 0){
            setProperties(properties => [...properties,...response.data.data.propertyList]);
            setRowCount(response.data.data.rowCount);
          }else{
            setIsTrue(false);
          }
        })
        .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    }
  
    const header = renderHeader();

    useEffect(() => {
        fetchProperties();
    }, []);

    const dynamicColumns = columns.map((col, i) => {
      if(col.header === 'Actions'){
        return <Column header={col.header} body={actionBodyTemplate} exportable={false}></Column>;
      }if(col.header === 'Status'){
        return <Column header={col.header} body={statusBodyTemplate} exportable={false}></Column>;
      }if(col.header === 'Image'){
        return <Column header={col.header} body={imageBodyTemplate} exportable={false}></Column>;
      }if(col.header === 'Brochure'){
        return <Column header={col.header} body={brochureBodyTemplate} exportable={false}></Column>;
      }if(col.header === 'Floor plan'){
        return <Column header={col.header} body={floorPlanBodyTemplate} exportable={false}></Column>;
      }else{
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
      }
    });

    return (
      <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmBoxTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmBoxMessage}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={eventHandle} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
            <div className="card">
                <DataTable value={properties} header={header} reorderableColumns reorderableRows onRowReorder={handleRowOrderChange} filters={filters} globalFilterFields={['id', 'status', 'title', 'listing_type', 'location_title','user.name','developer.name','square_feet','square_meter','amount','currency','date']}
                onScroll={scrollEvent}
                style={{ overflowY: 'scroll', height: '700px'}}
                emptyMessage="No property found.">
                    <Column rowReorder style={{ width: '3rem' }} />
                    {dynamicColumns}
                </DataTable>
            </div>
          </Card>
      </Flex>
      </>
    );
}
        